import React from "react"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" description="" />
      <Container className="mt-50 pt-5">
        <h3 className="mb-4">Your Repair Experts Privacy Guidelines</h3>
        <p className="font-weight-light">
          Effective date: August 20, 2018 <br />
          <br />
          Intermedia Marketing Group LLC (“us”, “we”, or “our”) operates the
          https://www.yourrepairexperts.com website (hereinafter referred to as
          the “Service”).
          <br />
          <br /> This page informs you of our policies regarding the collection,
          use and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
          <br />
          <br /> We use your data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, the terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions, accessible from
          https://www.yourrepairexperts.com
        </p>

        <h3>Definitions</h3>
        <h6>Service</h6>
        <p className="font-weight-light">
          Service is the https://www.yourrepairexperts.com website operated by
          Intermedia Marketing Group LLC
        </p>
        <h6>Personal Data</h6>
        <p className="font-weight-light">
          Personal Data means data about a living individual who can be
          identified from those data (or from those and other information either
          in our possession or likely to come into our possession).
        </p>
        <h6>Usage Data</h6>
        <p className="font-weight-light">
          Usage Data is data collected automatically either generated by the use
          of the Service or from the Service infrastructure itself (for example,
          the duration of a page visit).
        </p>
        <h6>Cookies</h6>
        <p className="font-weight-light">
          Cookies are small files stored on your device (computer or mobile
          device).
        </p>
        <h6>Data Controller</h6>
        <p className="font-weight-light">
          Data Controller means the natural or legal person who (either alone or
          jointly or in common with other persons) determines the purposes for
          which and the manner in which any personal information are, or are to
          be, processed.
        </p>
        <h6>Data Processors (or Service Providers)</h6>
        <p className="font-weight-light">
          Data Processor (or Service Provider) means any natural or legal person
          who processes the data on behalf of the Data Controller.
          <br />
          We may use the services of various Service Providers in order to
          process your data more effectively.
        </p>
        <h6>Data Subject (or User)</h6>
        <p className="font-weight-light">
          Data Subject is any living individual who is using our Service and is
          the subject of Personal Data.
        </p>
        <h3>Information Collection and Use</h3>
        <p className="font-weight-light">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h4>Types of Data Collected</h4>

        <h5>Personal Data</h5>
        <p className="font-weight-light">
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personally identifiable information
          may include, but is not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <p className="font-weight-light">
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link or the
          instructions provided in any email we send.
        </p>
        <h5>Usage Data</h5>
        <p className="font-weight-light">
          We may also collect information on how the Service is accessed and
          used (“Usage Data”). This Usage Data may include information such as
          your computer’s Internet Protocol address (e.g. IP address), browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages, unique
          device identifiers and other diagnostic data.
        </p>
        <h5>Location Data</h5>
        <p className="font-weight-light">
          We may use and store information about your location if you give us
          permission to do so (“Location Data”). We use this data to provide
          features of our Service, to improve and customise our Service. You can
          enable or disable location services when you use our Service at any
          time by way of your device settings.
        </p>
        <h5>Tracking Cookies Data</h5>
        <p className="font-weight-light">
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
          <br />
          <br />
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyse our Service. <br />
          <br />
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
          <br />
          <br />
          Examples of Cookies we use:
          <ul>
            <li>
              <strong>Session Cookies.</strong>&nbsp;We use Session Cookies to
              operate our Service.
            </li>
            <li>
              <strong>Preference Cookies.</strong>&nbsp;We use Preference
              Cookies to remember your preferences and various settings.
            </li>
            <li>
              <strong>Security Cookies.</strong>&nbsp;We use Security Cookies
              for security purposes.
            </li>
          </ul>
        </p>
        <h3>Use of Data</h3>
        <p className="font-weight-light">
          Intermedia Marketing Group LLC uses the collected data for various
          purposes:
        </p>
        <ul className="font-weight-light">
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer support</li>
          <li>
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
          <li>
            To provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information
          </li>
        </ul>
      </Container>
    </Layout>
  )
}

export default PrivacyPolicy
